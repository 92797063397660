<template>
  <div class="analytics-usage container tw-text-left tw-mx-0 tw-ml-0 tw-ml-2">
    <AnalyticsUsageNav class="tw-z-10 tw-relative" />
    <div class="tab-content pt-3 tw-z-0 tw-relative">
      <router-view />
    </div>
  </div>
</template>

<script>
import AnalyticsUsageNav from './AnalyticsUsageNav'

export default {
  name: 'AnalyticsUsage',
  components: {
    AnalyticsUsageNav
  },
  beforeCreate () {
    // https://owloutcomes.atlassian.net/browse/OWL-9791 - shared body class causes overflow to be hidden
    document.body.classList.remove('body-clinician-layout')
  }
}
</script>
