<template>
  <div class="analytics-usage-nav tw-flex">
    <div class="nav nav-header">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="{name: item.link}"
        class="nav-link"
        active-class="active"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="tw-flex-grow tw-self-end tw-pb-3 tw-flex">
      <owl-date-range-selector-dropdown
        class="tw-ml-auto"
        :start="params.start"
        :end="params.end"
        menu-alignment="right"
        @update="updateDateParams"
      />
    </div>
  </div>
</template>

<script>
import { OwlDateRangeSelectorDropdown } from 'owl-components'
import { getTickAndGraphIntervalFromISO } from 'owl-components/src/helpers/chartHelpers.js'
import { mapGetters } from 'vuex'
import { RESET_USAGE_GLOBAL_PARAMS, UPDATE_USAGE_GLOBAL_PARAMS, RESET_USAGE_TODAY_PARAMS, UPDATE_USAGE_TODAY_PARAMS } from '@/store/modules/analytics/usage/constants.js'

export default {
  name: 'AnalyticsUsageNav',
  components: {
    OwlDateRangeSelectorDropdown
  },
  computed: {
    ...mapGetters({
      params: 'getAnalyticsUsageGlobalParams'
    }),
    items () {
      return [{
        title: this.$t('analyticsUsage.patientsTabHeader'),
        link: 'AnalyticsUsagePatients'
      },
      {
        title: this.$t('analyticsUsage.clinicalTabHeader'),
        link: 'AnalyticsUsageClinical'
      },
      {
        title: this.$t('analyticsUsage.measuresTabHeader'),
        link: 'AnalyticsUsageMeasures'
      }]
    }
  },
  created () {
    this.$store.dispatch(RESET_USAGE_GLOBAL_PARAMS)
    this.$store.dispatch(RESET_USAGE_TODAY_PARAMS)
  },
  methods: {
    updateDateParams (value) {
      const newParams = {
        ...this.params,
        start: value.start,
        end: value.end,
        graph_interval: getTickAndGraphIntervalFromISO(value.start, value.end).points,
        // added to allow for setting different interval params for different charts
        graph_interval_patientusage: getTickAndGraphIntervalFromISO(value.start, value.end, value.selectionType).patientUsagePoints
      }

      this.$store.dispatch(UPDATE_USAGE_GLOBAL_PARAMS, newParams)
      this.$store.dispatch(UPDATE_USAGE_TODAY_PARAMS, { graph_interval: newParams.graph_interval, graph_interval_patientusage: newParams.graph_interval_patientusage })
    }
  }
}
</script>

<style lang="scss" scoped>
  .analytics-usage-nav {
    border-bottom: 2px solid #dee5ed;

    .nav {
      margin: 10px 0 0 0;
      font-size: 20px;
      font-weight: 300;

      a:link, a:active, a:hover, a:visited {
        color: #0a3560;
      }
      a.active {
        border-bottom: 4px solid #50e2d5;
        font-weight: 600;
      }
    }
  }
</style>
